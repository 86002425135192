import { toRef } from 'vue';
import { useResolveFontValue } from './useResolveFontValue';
import { useIsCheckedFontItem } from './useIsCheckedFontItem';
import { useRemoveDuplicateFonts } from './useRemoveDuplicateFonts';
import type { TypographyFontOption, TypographyV2Props } from '../../../types';
import type { FontItem, FontName } from '../types';
import { useCheckIsRecentFont } from './useCheckIsRecentFont';

type PropsType = {
  id: string;
  value?: string | TypographyV2Props['fontFamily'];
  options?: TypographyFontOption[];
  fontUploads?: TypographyFontOption[];
  wrapperClass?: string;
  middleware?: any[];
  currentComponentSetting?: any;
  globalStyleFont?: Record<string, FontItem>;
  shopStore?: any;
  editorStore?: any;
  isFreePlan?: boolean;
  themeFonts?: Partial<Record<FontName, FontItem>>;
};

export function useFontFamilySelect(props: PropsType) {
  const currentFont = toRef(props, 'value');
  const themeFonts = toRef(props, 'themeFonts');

  const isRecentFont = useCheckIsRecentFont(themeFonts);
  const resolveFontValue = useResolveFontValue(themeFonts);
  const isCheckedFontItem = useIsCheckedFontItem(themeFonts, currentFont);
  const removeDuplicateFonts = useRemoveDuplicateFonts(themeFonts, currentFont);
  return {
    isRecentFont,
    resolveFontValue,
    isCheckedFontItem,
    removeDuplicateFonts,
  };
}
